import Layout from "./common/layout"
import Head from "./common/Head"
import BannerHeader from "pages/common/BannerHeader"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"
import bannerMobile from "images/privacy-mobile.svg"
import PrivacyPolicyVN from "./privacyPolicy/components/PrivacyPolicyVN"
import PrivacyPolicyEN from "./privacyPolicy/components/PrivacyPolicyEN"

const PrivacyPolicy = () => {
  const { __ } = useFormatter()
  const banner = "../images/privacy.png"

  const { currentLocale } = useFormatter()

  const renderTitle = title => (
    <p
      style={{
        fontSize: 16,
        fontWeight: 700,
        margin: "24px auto",
      }}
    >
      {title}
    </p>
  )
  return (
    <>
      <Head
        showCaptcha={false}
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/clients-partner-meta.png"
        }
        title={__({ defaultMessage: "Privacy Policy" })}
        description={__({
          defaultMessage:
            "In order to perform and provide the Services to Customer, We need to collect and use the information and data of Customer. This privacy policy (“Privacy Policy” or “Policy”) shall help Customer to understand how We collect, use and/or process Customers’ personal data provided to us",
        })}
      />
      <Layout>
        <div>
          <BannerHeader
            breadcrumb={[
              {
                label: `${__({ defaultMessage: "Home/" })}`,
                url: "/",
              },
              { label: `${__({ defaultMessage: "Privacy Policy" })}` },
            ]}
            banner={
              <StaticImage
                layout="fullWidth"
                src={banner}
                alt=""
                decoding="async"
                fetchpriority="high"
                quality={100}
              />
            }
            mobileBanner={
              <img src={bannerMobile} alt="" style={{ width: "100%" }} />
            }
            logo={""}
            listTitle={[`${__({ defaultMessage: "Privacy Policy" })}`]}
            description={`${__({
              defaultMessage:
                "In order to perform and provide the Services to Customer, We need to collect and use the information and data of Customer. This privacy policy (“Privacy Policy” or “Policy”) shall help Customer to understand how We collect, use and/or process Customers’ personal data provided to us.",
            })}
            `}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 64,
            }}
          >
            <div style={{ flex: 1 }}></div>
            {currentLocale == "vi" ? <PrivacyPolicyVN /> : <PrivacyPolicyEN />}
            <div style={{ flex: 1 }}></div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy

const P = ({ children }) => {
  return <p style={{ marginTop: 15 }}>{children}</p>
}
